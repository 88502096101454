import React from 'react';

const PurchaseSuccess = () => {
    return ( 
        <>
        <h1>Success</h1>
        </>
     );
}
 
export default PurchaseSuccess;